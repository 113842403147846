@use "../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        display: inline-flex;
        margin: 0;
    }

    .button {
        align-items: center;
        background: var(--s-color-surface-quiet);
        border-radius: var(--s-border-radius-background-standard);
        border: none;
        color: var(--s-color-selection-foreground-default);
        cursor: pointer;
        display: flex;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-regular);
        gap: var(--g-spacing-sm);
        line-height: var(--g-line-height-400);
        margin: 0;
        outline: var(--g-border-width-xs) solid transparent;
        padding-bottom: var(--g-spacing-xs);
        padding-left: var(--g-spacing-md);
        padding-right: var(--g-spacing-sm);
        padding-top: var(--g-spacing-xs);
        transition: transform var(--g-duration-xshort) ease;

        &:hover,
        &:active,
        &:focus-visible {
            color: var(--s-color-selection-foreground-selected-quiet);
        }

        &:active {
            transform: scale(0.96);
        }

        &:focus-visible {
            outline: var(--g-border-width-sm) solid
                var(--s-color-selection-border-focus);
        }

        @media (prefers-reduced-motion: reduce), (forced-colors: active) {
            transition: none;
        }
    }
}
